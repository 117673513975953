import React from "react";
import { toggleIntercom } from "./CommonUtils";
import { PERMISSION_VIEW_ALL, PERMISSION_SUMMARY_ANALYSIS } from "../constants";
import Router from "../routes";
import Link from "../components/theme/3.0/Link";

export const knowledgeSteps = (user) => [
    {
        disableBeacon: true,
        target: '.main-menu [title="Resources"]',
        content: (
            <React.Fragment>
                Convergence’s Resource Library is a curated repository of the
                best research and analysis on blended finance. This library
                includes Convergence’s own research and publications, such as
                case studies and member-only data briefs, as well as relevant
                knowledge produced by other leading experts in the field,
                including Convergence’s partner organizations.
                <br />
                <br />
                Our resources page can be filtered according to key criteria
                including type of knowledge product, sector, and regional focus.{" "}
                <a href="#" onClick={toggleIntercom}>
                    Contact us
                </a>{" "}
                or reach out to your relationship manager if you have specific
                topics of interest or can’t find what you’re looking for.
                <br />
                <br />
                New knowledge products are announced via email, so keep a look
                out!
            </React.Fragment>
        ),
        styles: {
            options: {
                zIndex: 50000
            }
        }
    },
    {
        disableBeacon: true,
        target: '.main-menu [title="Members"]',
        content: (
            <React.Fragment>
                Let’s move on to our Members page , where you will find a living
                list of fellow Convergence members, and information on what
                they’re doing in blended finance, and how you can best
                collaborate to advance SDG-aligned development projects.
            </React.Fragment>
        ),
        styles: {
            options: {
                zIndex: 50000
            }
        },
        disableOverlayClose: true,
        locale: {
            last: "View Members"
        },
        redirect: "institution"
    }
];

export const trainingSteps = (user) => [
    {
        disableBeacon: true,
        target: '.main-menu [title="Training"]',
        content: (
            <React.Fragment>
                Convergence offers a number of training events for you and your
                team to build capacity on blended finance topics. Watch this
                space for upcoming events and webinars, andcheck out our library
                of videos to get quick insights on key blended finance
                questions. Beyond scheduled training, Convergence offers
                customized training workshops tailored to your organization's
                unique needs and challenges. Interested in a customized training
                for your organization?{" "}
                <a href="#" onClick={toggleIntercom}>
                    Contact us
                </a>{" "}
                here or reach out to your relationship manager to discuss.
            </React.Fragment>
        ),
        styles: {
            options: {
                zIndex: 50000
            }
        }
    },
    {
        disableBeacon: true,
        target: '.main-menu [title="Members"]',
        content: (
            <React.Fragment>
                Let’s move on to our Members page , where you will find a living
                list of fellow Convergence members, and information on what
                they’re doing in blended finance, and how you can best
                collaborate to advance SDG-aligned development projects.
            </React.Fragment>
        ),
        styles: {
            options: {
                zIndex: 50000
            }
        },
        disableOverlayClose: true,
        locale: {
            last: "View Members"
        },
        redirect: "institution"
    }
];

export const institutionSteps = () => [
    {
        disableBeacon: true,
        target: '.main-menu [title="Members"]',
        content: (
            <React.Fragment>
                Convergence has built a global community that includes public,
                private, and philanthropic investors as well as sponsors of
                transactions and funds. All of our members have an interest in
                supporting the growth of blended finance and are focused on
                increasing the flow of private sector investment towards
                sustainable development in developing countries.
            </React.Fragment>
        ),
        styles: {
            options: {
                zIndex: 50000
            }
        }
    },
    {
        target: ".filters-bar",
        content: (
            <React.Fragment>
                The search and filter functionalities will help you identify
                like minded members or potential partners or investors. Blended
                finance provides a unique opportunity to stay in your lane while
                partnering for impact.{" "}
                <a href="#" onClick={toggleIntercom}>
                    Contact us
                </a>
                for support on how to identify organizations to connect with.
            </React.Fragment>
        ),
        styles: {
            options: {
                zIndex: 50000
            }
        }
    },
    {
        target: ".table-deals > a:first-of-type",
        content: (
            <React.Fragment>
                Curious to know more about how certain members are engaging in
                blended finance? Check out their profile page to get an
                overview, including their areas of interest. Select{" "}
                <strong>historical deals</strong> to see their portfolio of
                blended finance transactions captured to date.
            </React.Fragment>
        ),
        styles: {
            options: {
                zIndex: 50000
            }
        },
        hideFooter: true,
        spotlightClicks: true
    }
];

export const investorDataSteps = (user, authorizationService) => {
    const steps = [
        {
            disableBeacon: true,
            target: '.main-menu [title="Investor Data"]',
            content: (
                <React.Fragment>
                    Here you will find information on the investors that have
                    participated in the blended finance transactions captured in
                    our Historical Deals Database. Information includes data
                    points on the number of deals participated in, their average
                    investment size, and their most commonly targeted sectors.
                    This is a great tool to understand what your peers are doing
                    or to identify potential investors for your vehicle.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        },
        {
            target: "#investor-data-summary-analysis",
            content: (
                <React.Fragment>
                    Our summary analysis tool reflects the aggregate investor
                    information from our Historical Deal Database. You can learn
                    about where the most active investors are based, what
                    various investor types have historically invested in and who
                    is most active.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        },
        {
            target: ".filters-bar",
            content: (
                <React.Fragment>
                    The Filter or Search functionality allows you to target data
                    according to key deal data points, including sector, region,
                    and SDG. If you’re currently structuring a blended finance
                    transaction, use this tool to identify potential funders and
                    investors. Need help with this?{" "}
                    <a href="#" onClick={toggleIntercom}>
                        Contact us
                    </a>
                    .
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        }
    ];

    if (
        authorizationService.hasPermission("deal", PERMISSION_SUMMARY_ANALYSIS)
    ) {
        steps.push({
            disableBeacon: true,
            target: '.main-menu [title="Deals"]',
            content: (
                <React.Fragment>
                    Now that you have the lay of the land here, let’s check out
                    the Fundraising Deals Database.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            },
            // @ts-ignore POSSIBLE ERROR FOUND unknown property locale
            locale: {
                last: "View Fundraising Deals"
            },
            // @ts-ignore POSSIBLE ERROR FOUND unknown property disableOverlayClose
            disableOverlayClose: true,
            // @ts-ignore POSSIBLE ERROR FOUND unknown property redirect
            redirect: "deals-summary-analysis"
        });
    } else {
        steps.push({
            disableBeacon: true,
            target: '.main-menu [title="Resources"]',
            content: (
                <React.Fragment>
                    Now that you have the lay of the land here, let’s check out
                    the Resources Library.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            },
            // @ts-ignore POSSIBLE ERROR FOUND unknown property disableOverlayClose
            disableOverlayClose: true,
            locale: {
                last: "View Resources"
            },
            redirect: "knowledge"
        });
    }

    return steps;
};

export const fundraisingDealsSteps = (user, authorizationService) => {
    const steps = [
        {
            disableBeacon: true,
            target: '.main-menu [title="Deals"]',
            content: (
                <React.Fragment>
                    Welcome to the Fundraising Deals Database. We provide our
                    investor-members access to the only dedicated pipeline of
                    fundraising blended finance deals. Members can post deals
                    they are sponsoring, advising, or investing in. Every
                    transaction has: i) a blended finance structure, ii)
                    confirmed third party interest, iii) a minimum overall
                    transaction size of $5 million, and target at least one
                    Sustainable Development Goal in a developing country(ies).
                    Interested to learn more about this offering?{" "}
                    <a href="#" onClick={toggleIntercom}>
                        Contact us
                    </a>
                    .
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        },
        {
            target: "#fundraising-deals-summary-analysis",
            content: (
                <React.Fragment>
                    Our summary analysis tool reflects the aggregate information
                    of what's currently listed on our Fundraising Deal Database.
                    Can’t find the types of deals you’re searching for?{" "}
                    <a href="#" onClick={toggleIntercom}>
                        Contact us
                    </a>{" "}
                    or reach out to your relationship manager to discuss your
                    investment priorities and ensure your '
                    <Link
                        routeKey={"settings-profile"}
                        label={"personal interests"}
                        target={"_blank"}
                    />
                    ' are up to date.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        },
        {
            target: ".filters-bar",
            content: (
                <React.Fragment>
                    Filter or Search live deals according to key deal data
                    points, including sector, region, and SDG or deal sponsor
                    type to identify summary information on the types of deals
                    most relevant to you.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        }
    ];

    if (authorizationService.hasPermission("deal", PERMISSION_VIEW_ALL)) {
        steps.push({
            target: "#all-fundraising-deals",
            // @ts-ignore POSSIBLE ERROR FOUND unknown property lifecycle
            lifecycle: {
                before: () => {
                    Router.push("deals");
                }
            },
            content: (
                <React.Fragment>
                    Use this tab to scroll all active live deals, and review
                    specific fundraising deals. See something you’re interested
                    in?
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        });
        steps.push({
            target: ".table-deals > .deal-row-container",
            // @ts-ignore POSSIBLE ERROR FOUND unknown property lifecycle
            lifecycle: {
                before: (target) => {
                    if (typeof target !== "undefined") {
                        const targetElement = document.querySelector(
                            `${target} > .row-table`
                        );
                        if (targetElement) {
                            // @ts-ignore POSSIBLE ERROR FOUND click does not exist on Element
                            targetElement.click();
                        }
                    }
                }
            },
            content: (
                <React.Fragment>
                    Select the deal name to see a preview of the transaction.
                    You can then view more detailed information on the
                    transaction or deal sponsor, and can start a conversation
                    with the deal sponsor to learn more.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        });
    }

    steps.push({
        disableBeacon: true,
        target: '.main-menu [title="Resources"]',
        content: (
            <React.Fragment>
                Now that you are familiar with our Fundraising Deals platform,
                let’s head to Convergence’s <strong>Resources</strong> library.
            </React.Fragment>
        ),
        styles: {
            options: {
                zIndex: 50000
            }
        },
        // @ts-ignore POSSIBLE ERROR FOUND unknown property disableOverlaClose
        disableOverlayClose: true,
        locale: {
            last: "View Resources"
        },
        redirect: "knowledge"
    });

    return steps;
};

export const historicalDealsSteps = (user, authorizationService) => {
    const steps = [
        {
            disableBeacon: true,
            target: '.main-menu [title="Deals"]',
            content: (
                <React.Fragment>
                    Convergence’s Historical Deals Database is the largest and
                    most comprehensive database of blended finance transactions
                    available globally. We continuously update this database to
                    capture real-time blended finance activity. Transactions are
                    included in this database once they have achieved financial
                    close.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        },
        {
            target: "#historical-deals-summary-analysis",
            content: (
                <React.Fragment>
                    Our summary analysis tool illustrates the aggregate
                    historical deals data and allows you to filter according to
                    key deal data points, including sector, region, and blending
                    archetype, as well as participating institution data points,
                    and, investor and deal sponsor type. Not sure how to find
                    what you’re looking for?{" "}
                    <a href="#" onClick={toggleIntercom}>
                        Contact us
                    </a>{" "}
                    so we can help!
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        },
        {
            target: "#all-historical-deals",
            lifecycle: {
                before: () => {
                    Router.push("historical-deals");
                }
            },
            content: (
                <React.Fragment>
                    Once you’ve filtered for a subset you’re interested in,
                    select "All Historical Deals" to see the details on the
                    specific deals included in the sub-set.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        },
        {
            target: ".table-deals > .deal-row-container",
            lifecycle: {
                before: (target) => {
                    if (typeof target !== "undefined") {
                        const targetElement = document.querySelector(
                            `${target} > .row-table`
                        );
                        if (targetElement) {
                            // @ts-ignore POSSIBLE ERROR FOUND click does not exist on Element
                            targetElement.click();
                        }
                    }
                }
            },
            content: (
                <React.Fragment>
                    See a deal that peaks your interest? Select the deal name to
                    see a preview of the transaction. If you’re interested in
                    learning more, select ‘View More Details’ to see additional
                    details on the deal structure, breakdown of investors, and
                    impact.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        }
    ];

    steps.push({
        disableBeacon: true,
        target: '.main-menu [title="Investor Data"]',
        content: (
            <React.Fragment>
                Great, you’re up to speed on the historical deals Convergence
                has captured! Let’s move on to the Investor Data page.
            </React.Fragment>
        ),
        styles: {
            options: {
                zIndex: 50000
            }
        },
        // @ts-ignore POSSIBLE ERROR FOUND unknown property disableOverlayClose
        disableOverlayClose: true,
        locale: {
            last: "View Investor Data"
        },
        redirect: "investor-data-summary-analysis"
    });

    return steps;
};

export const dashboardSteps = (user, authorizationService) => {
    const steps = [
        {
            disableBeacon: true,
            target: '.main-menu [title="Home"]',
            content: (
                <React.Fragment>
                    Welcome to Convergence! Your Member Dashboard or ‘Home’ page
                    is where you will find aggregated information from our
                    website targeted to your specific interests, including
                    recent blended finance knowledge, upcoming events, and
                    curated deals.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        },
        {
            target: "#quick-links-widget",
            placement: "right",
            content: (
                <React.Fragment>
                    New to blended finance? Quick links are a great place to
                    start. Check out our Blended Finance Primer, upcoming and
                    past trainings, opportunities to secure grant funding for
                    your early-stage blended finance vehicle, or see what's
                    currently listed on our fundraising deal platform at a
                    glance.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        },
        {
            target: ".dashboard-navigation",
            placement: "bottom",
            content: (
                <React.Fragment>
                    Use this menu bar to make changes to your interests at any
                    time (via your profile). Through these shortcuts, you can
                    review fundraising deals that match your interests, view or
                    submit your own fundraising deals, identify matched
                    investors, or access your inbox to view recent messages from
                    other members.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        },
        {
            target: "#chart-of-the-week-widget",
            placement: "left-start",
            content: (
                <React.Fragment>
                    Each week we explore a question in the form of a chart and
                    provide additional resources as a starting point to improve
                    your understanding of different blended finance topics. Do
                    you have a blended finance question you’d like us to
                    explore?{" "}
                    <a href="#" onClick={toggleIntercom}>
                        Contact us
                    </a>{" "}
                    or reach out to your relationship manager to let us know.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        },
        {
            target: "#relevant-knowledge-widget",
            placement: "right-start",
            content: (
                <React.Fragment>
                    This widget features our most recent publications including
                    Convergence-authored case studies, reports, member-exclusive
                    data briefs and more. Select ‘view all’ to search and filter
                    our complete resource library for your specific areas of
                    interest.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        }
    ];

    if (authorizationService.hasPermission("deal", PERMISSION_VIEW_ALL)) {
        steps.push({
            target: "#matched-active-deals-widget",
            placement: "right-start",
            content: (
                <React.Fragment>
                    Check out live fundraising deals that match your stated
                    interests. Select a specific transaction to learn more.
                    ‘View All’ will allow you to see the complete database and
                    to search and filter for different focus areas. If you are
                    interested in a deal, you can reach out directly to the
                    sponsor using the Message function.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        });
    }

    steps.push({
        target: "#events-widget",
        placement: "left-start",
        content: (
            <React.Fragment>
                Our event tracker will help you regularly stay on top of
                upcoming blended finance focused events, globally. Events marked
                with ‘Convergence’ are hosted by Convergence and are typically
                free or discounted for members!
            </React.Fragment>
        ),
        styles: {
            options: {
                zIndex: 50000
            }
        }
    });

    if (
        authorizationService.hasPermission("institution", PERMISSION_VIEW_ALL)
    ) {
        steps.push({
            target: "#latest-institutions-widget",
            placement: "right-start",
            content: (
                <React.Fragment>
                    Convergence is committed to growing the blended finance
                    network, globally. Check out this list of new members and
                    send a message directly to a fellow member organization by
                    visiting their profile page, which will feature more
                    information about who they are and what blended finance
                    activity they are engaged in. Not sure who to connect with?{" "}
                    <a href="#" onClick={toggleIntercom}>
                        Contact us
                    </a>{" "}
                    or reach out to your relationship manager if you’re looking
                    to get ideas of who you should connect with.
                </React.Fragment>
            ),
            styles: {
                options: {
                    zIndex: 50000
                }
            }
        });
    }

    steps.push({
        target: "#twitter-widget",
        placement: "left-start",
        content: (
            <React.Fragment>
                Check out this section to see the latest from us on Twitter
            </React.Fragment>
        ),
        styles: {
            options: {
                zIndex: 50000
            }
        }
    });

    steps.push({
        target: "#news-widget",
        placement: "right-start",
        content: (
            <React.Fragment>
                Convergence is continuously publishing new blogs and content,
                check out this section to see our latest announcements, blogs,
                and videos.
            </React.Fragment>
        ),
        styles: {
            options: {
                zIndex: 50000
            }
        }
    });

    steps.push({
        disableBeacon: true,
        target: '.main-menu [title="Deals"]',
        content: (
            <React.Fragment>
                Now that you know the basics, let’s check out our Historical
                Deals Database to get a sense of the blended finance universe.
            </React.Fragment>
        ),
        styles: {
            options: {
                zIndex: 50000
            }
        },
        // @ts-ignore POSSIBLE ERROR FOUND unknown property disableOverlayClose
        disableOverlayClose: true,
        locale: {
            last: "View Historical Deals"
        },
        redirect: "historical-deals-summary-analysis"
    });

    return steps;
};
